window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());

gtag('config', 'UA-126661750-1');

  var newDate = new Date().getFullYear();
  var copy = document.getElementById('copy');
  copy.innerHTML = newDate;
//open/close primary navigation
var header = document.querySelector('.header');
var menuTrigger = document.querySelector('.nav-trigger');
var navigation = document.querySelector('.navigations');
var menuIcon = document.querySelector('.icon-menu');
var body = document.querySelector('body');
menuTrigger.onclick=function(e) {
  header.classList.toggle('open');
  menuTrigger.classList.toggle('is-clicked');
  navigation.classList.toggle('is-visible');
  body.classList.toggle('overflow-hidden');
}
navigation.addEventListener('click', function(e) {
  if (e.target && e.target.nodeName == 'LI' || e.target.nodeName == "A") {
    header.classList.remove('open');
    menuTrigger.classList.remove('is-clicked');
    navigation.classList.remove('is-visible');
  }
});
if (Modernizr.matchmedia) {
  var windowWidth = window.matchMedia('(min-width: 1024px)');
      checkWidth(windowWidth);
      windowWidth.addListener(checkWidth);
} else {
  console.log('El browser no soporta matchMedia');
}

function checkWidth(windowWidth) {
  if (windowWidth.matches) {
    header.classList.remove('open');
    menuTrigger.classList.remove('is-clicked');
    navigation.classList.remove('is-visible');
    body.classList.remove('overflow-hidden');
  }
}
var slider = document.getElementById('slider');
if (document.body.contains(slider)) {
  $('#slider').slick({
    infinite: true,
    speed: 300,
    autoplay: true,
    autoplaySpeed: 6000,
    arrows: true,
    prevArrow: $('.icon-prev'),
    nextArrow: $('.icon-nextel'),
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true
        }
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true
        }
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true
        }
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true
        }
      }
    ]
  });
}
//comienza slider Productos
