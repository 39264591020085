// var regPatentes = [a-zA-Z]{2,2}[0-9]{3,3}[a-zA-Z]{2,2}|[a-zA-Z]{3,3}[0-9]{3,3};

//buttons that open the form
var floatButton = document.querySelector('#floatButton');
var abrirCotizador = document.getElementById('abrirCotizador');
var cotizadorWrapper = document.querySelector('.cotizador');
var iecotizador = document.querySelector('.ie-cotizador');
var target;
//listen for click on those buttons to load content.
if (document.body.contains(abrirCotizador)) {
  abrirCotizador.onclick = function(e) {
    target = e.target.value;
    displayFormContent(target);
  }
}
if (document.body.contains(floatButton)) {
  floatButton.onclick = function() {
    displayFormContent();
  }
}
if (document.body.contains(iecotizador)) {

}
function displayFormContent() {
  var request = new XMLHttpRequest();
  request.open('GET', 'partial/cotizadorForm.html');
  request.onload = function() {
    cotizadorWrapper.innerHTML = request.response;
    var cotizadorContainer = document.querySelector('.cotizador-container');
    var form = document.querySelector('.formCotizador');
    var checkboxAutomotor = document.getElementById('fautomotor');
    var checkboxOtro = document.querySelector('#fotro');
    var formAutomotor = document.querySelector('#optionAutomotor');
    var formOtroNote = document.querySelector('.otro');
    var formAutomotorNote = document.querySelector('.automotor');
    var formOtro = document.querySelector('.cotizarotro');
    var cotizar = document.querySelectorAll('.cotizar');
    var formRadios = document.querySelectorAll('.radio_container');
    //var inputsAutomotor = formAutomotor.querySelectorAll('input');
    var inputsAutomotor = document.querySelectorAll('#optionAutomotor input');
    //var radioAutomotor = formAutomotor.querySelector('input[type="radio"]');
    var radioAutomotor = document.querySelector('#optionAutomotor input[type="radio"]');
    //var selectAutomotor = formAutomotor.querySelector('select');
    var selectAutomotor = document.querySelector('#optionAutomotor select');
    // var inputsOtro = formOtro.querySelectorAll('input[type="radio"]');
    var inputsOtro = document.querySelectorAll('#formOtro input[type="radio"]');
    var distintoContainer = document.querySelector('#distinto-container');
    var inputDistinto = document.querySelector('input[id="distinto"]');
    var formmensajes = document.getElementById('fconfirmation');

    openForm();
    switch (target) {
      case 'integralcomercio':
        openOtroform('integralcomercio');
        break;
      case 'combinadofamiliar':
        openOtroform('combinadofamiliar');
        break;
      case 'art':
        openOtroform('art');
        break;
      case 'accidentespersonales':
        openOtroform('accidentespersonales');
        break;
      case 'caucion':
        openOtroform('caucion');
        break;
      case 'saludprepaga':
        openOtroform('saludprepaga');
        break;
      case 'vidaretiro':
        openOtroform('segurosvidaretiro');
        break;
      case 'empresacontratista':
        openOtroform('empresacontratista');
        break;
      case 'other':
        checkboxOtro.checked = true;
        showElement(formOtro, 'hidden');
        showElement(formOtroNote, 'hidden');
        hideElement(formAutomotorNote, 'hidden');
        break;
      case 'automotor':
        checkboxAutomotor.checked = true;
        showElement(formAutomotor, 'hidden');
        break;
      default:
        break;
    }

    function openForm() {
      showElement(cotizadorWrapper, 'hidden');
      hideElement(cotizadorContainer, 'open');
      hideElement(body, 'overflow');
    }
    function closeForm() {
      hideElement(cotizadorWrapper, 'hidden');
      showElement(cotizadorContainer, 'open');
      showElement(body, 'overflow');
    }
    function cleanForm() {
      getElements('input[type="radio"]').forEach(function(el) {
        el.checked = false;
      });
      getElements('input[type="text"]').forEach(function(el) {
        el.value = '';
      });
      getElements('#fconsulta').forEach(function(el) {
        el.value = '';
      });
      getElements('.cotizar').forEach(function(el) {
        hideElement(el, 'hidden');
      });
    }
    function openOtroform(targetValue) {
      checkboxOtro.checked = true;
      showElement(formOtro, 'hidden');
      showElement(formOtroNote, 'hidden');
      hideElement(formAutomotorNote, 'hidden');
      form.querySelector('#' + targetValue).checked = true;
    }

      //listen for changes in the form
      cotizadorContainer.addEventListener('change', function() {
        if (checkboxAutomotor.checked === true) {
          showElement(formAutomotor, 'hidden');
          hideElement(formOtroNote, 'hidden');
          hideElement(formOtro, 'hidden');
          showElement(formAutomotorNote, 'hidden');
          //remove the checked inputs form otro
          for (var i = 0; i < inputsOtro.length; i++) {
            inputsOtro[i].checked = false;
          }
        }
        else if (checkboxOtro.checked === true) {
          //if user selects cotizar otro
          hideElement(formAutomotor, 'hidden');
          showElement(formOtroNote, 'hidden');
          showElement(formOtro, 'hidden');
          hideElement(formAutomotorNote, 'hidden');
          //remove the values for inputs from automotor form
          for (var i = 0; i < inputsAutomotor.length; i++) {
            inputsAutomotor[i].value = '';
          }
          //remove the checked for radio button
          radioAutomotor.checked = false;
          //remove selected option
          selectAutomotor.selectedIndex = 0;
          //if inputdistinto is checked show text imput
          if (inputDistinto.checked == true) {
            showElement(distintoContainer, 'hidden');
          } else {
            hideElement(distintoContainer, 'hidden');
          }
        }
      });
      //close form when outside is clicked.
      document.onclick = function(e) {
        if (e.target.className === 'cotizador-container open' || e.target.className === 'col-12 right close-button-container' || e.target.className === 'close-button') {
          closeForm();
          cleanForm();
        }
      }
      document.onkeyup = function(e) {
        e = e || window.event;
        if (e.keyCode == 27) {
          closeForm();
          cleanForm();
        }
      }
    $.validator.addMethod('wrongValue', function(value, element, arg) {
        return arg !== value;
      }, 'el valor es incorrecto');
      function injectTrim(handler) {
        return function (element, event) {
          if (element.name === 'femail' || element.name === 'fphone') {
            element.value = $.trim(element.value);
          }
          return handler.call(this, element, event);
        };
      }
    $(form).validate({
        onkeyup: injectTrim($.validator.defaults.onkeyup),
        rules: {
          ftipocotizacion: {
            required: true
          },
          cotizarotro: {
            required: function(element) {
              if (checkboxOtro.checked == true) {
                return true;
              } else {
                return false;
              }
            }
          },
          fname: {
            required: true,
            minlength: 5
          },
          fphone: {
            required: {
              depends: function(element) {
                return $('#femail').val() == '';
              }
            },
            digits: true
          },
          femail: {
            required: true,
            email: true
          },
          fmodelo: {
            required: function(element) {
              if (checkboxOtro.checked == false) {
                return true;
              } else {
                return false;
              }
            }
          },
          fmarca: {
            required: function(element) {
              if (checkboxOtro.checked == false) {
                return true;
              } else {
                return false;
              }
            }
          },
          fanio: {
            required: function(element) {
              if (checkboxOtro.checked == false) {
                return true;
              } else {
                return false;
              }
            },
            pattern: /^\d{4}$/
          },
          fpatente: {
            required: function(element) {
              if (checkboxOtro.checked == false) {
                return true;
              } else {
                return false;
              }
            }
          },
          fciudad: {
            required: function(element) {
              if (checkboxOtro.checked == false) {
                return true;
              } else {
                return false;
              }
            }
          },
          fgnc: {
            required: function(element) {
              if (checkboxOtro.checked == false) {
                return true;
              } else {
                return false;
              }
            }
          },
          ftipocobertura: {
            required: function(element) {
              if (checkboxOtro.checked == false) {
                return true;
              } else {
                return false;
              }
            },
            wrongValue: function(element) {
              if (checkboxOtro.checked == false) {
                return '0';
              } else {
                return false;
              }
            }
          },
          distintoinput: {
            required: function(element) {
              if (inputDistinto.checked) {
                return true;
              } else {
                return false;
              }
            }
          },
          fconsulta: {
            required: function(element) {
              if (checkboxOtro.checked == true) {
                return true;
              } else {
                return false;
              }
            }
          }
        },
        messages: {
          ftipocotizacion: {
            required: 'Debes seleccionar el tipo de cotización que necesitas'
          },
          cotizarotro: {
            required: 'Debes indicar qué tipo de cotización deseas hacer, por ejemplo: hogar, integral de comercio, etc.'
          },
          fname: {
            required: 'Debes colocar tu nombre',
            minlength: 'Tu nombre debe tener al menos 5 letras'
          },
          femail: {
            required: 'Debes colocar tu email para que podamos contactarte',
            email: 'Debes colocar un email válido'
          },
          fphone: {
            required: 'Debes colocar tu teléfono para que podamos contactarte',
            digits: 'El número de teléfono sólo debe contener números'
          },
          fmodelo: {
            required: 'Debes indicar el modelo del automotor'
          },
          fmarca: {
            required: 'Debes indicar la marca del automotor'
          },
          fanio: {
            required: 'Debes indicar el año del automotor',
            pattern: 'El formato es inválido, debe ser por ejemplo: 2018'
          },
          fpatente: {
            required: 'Debes indicar la pantente del automotor'
          },
          fciudad: {
            required: 'Debes indicar la ciudad del automotor'
          },
          fgnc: {
            required: 'Debes indicar si el automotor tiene GNC o no'
          },
          distintoinput: {
            required: 'Debes colocar el tipo de cobertura que deseas cotizar'
          },
          ftipocobertura: {
            required: 'Debes indicar el tipo de cobertura que deseas cotizar',
            wrongValue: 'Debes seleccionar una opción de cobertura'
          },
          fconsulta: {
            required: 'Dejános un mensaje sobre el tipo de cobertura que deseas cotizar'
          }
        },
        errorPlacement: function(error, element) {
            if (element.is(":radio"))
              error.appendTo(element.parent().parent());
            else if (element.is(":checkbox"))
              error.appendTo(element.parent());
            else
              error.appendTo(element.parent());
        },
        submitHandler: function(form) {
          $.ajax({
            type: 'POST',
            url: $(form).attr('action'),
            data: $(form).serialize(),
          })
          .done(function(response) {
            $(formmensajes).removeClass('error');
            $(formmensajes).addClass('success');
            $(formmensajes).text('Tu mensaje ha sido enviado correctamente!');
            //remove all data after sending

            $('submit[name="fsubmit"]').addClass('success');
            $(form).find('input[type="radio"]').prop('checked', false);
            $(form).find('input[type="text"]').val('');
            $('#fconsulta').val('');
            $(form).find('.cotizar').addClass('hidden');
          })
          .fail(function(data) {
            $(formmensajes).removeClass('success');
            $(formmensajes).addClass('error');
            if (data.responseText !== '') {
              $(formmensajes).text(data.responseText);
            } else {
              $(formmensajes).text('Ha ocurrido un error, no se ha podido enviar el mensaje, intenta nuevamente.');
            }
          });
        }
      });
  }
  request.send();
}
function getElements(element) {
  return Array.prototype.slice.call(document.querySelectorAll(element));
}
function hideElement(element, classes) {
  element.classList.add(classes);
}
function showElement(element, classes) {
  element.classList.remove(classes);
}
