var contactForm = document.getElementById('formulario-contacto');
var formMessages = document.getElementById('form-messages');
if (document.body.contains(contactForm)) {
  $(contactForm).validate({
    rules: {
      cname: {
        required: true,
        minlength: 5
      },
      cemail: {
        required: true,
        email: true
      },
      cphone: {
        required: true,
        digits: true
      },
      cmessage: {
        required: true
      }
    },
    messages: {
      cname: {
        required: 'Debes colocar tu nombre',
        minlength: 'Tu nombre debe contener por lo menos 5 letras'
      },
      cemail: {
        required: 'Debes ingresar tu email',
        email: 'Debes ingresar un email válido'
      },
      cphone: {
        required: 'Debes ingresar un teléfono',
        digits: 'El teléfono debe contener sólo números'
      },
      cmessage: {
        required: 'Debes ingresar un mensaje'
      }
    },
    // errorPlacement: function(error, element) {
    //   error.insertBefore(element);
    // },
    errorPlacement: function(error, element) {
				if (element.is(":radio"))
					error.appendTo(element.parent().parent());
				else if (element.is(":checkbox"))
					error.appendTo(element.parent());
				else
					error.appendTo(element.parent());
		},
    submitHandler: function(form) {
      $.ajax({
        type: 'POST',
        url: $(form).attr('action'),
        data: $(form).serialize(),
      })
      .done(function(response) {
        $(formMessages).removeClass('error');
        $(formMessages).addClass('success');
        $(formMessages).text('Tu mensaje ha sido enviado correctamente');

        $('#csubmit').addClass('success');
        $('#cname').val('');
        $('#cemail').val('');
        $('#cphone').val('');
        $('#cmessage').val('');
      })
      .fail(function(data) {
        $(formMessages).removeClass('success');
        $(formMessages).addClass('error');
        if (data.responseText !== '') {
          $(formMessages).text(data.responseText);
        } else {
          $(formMessages).text('Ha ocurrido un error, no se ha podido enviar el mensaje, intenta nuevamente.');
        }
      });
    }
  });
}
var solicitudForm = document.getElementById('formulario-solicitud');
if (document.body.contains(solicitudForm)) {
  var mensajes = document.getElementById('confirmation');
  //var regPatentes = [a-zA-Z]{2,2}[0-9]{3,3}[a-zA-Z]{2,2}|[a-zA-Z]{3,3}[0-9]{3,3};
  $(solicitudForm).validate({
    rules: {
      sname: {
        required: true,
        minlength: 5
      },
      spatente: {
        required: true
      },
      semail: {
        required: true,
        email: true
      },
      smessage: {
        required: true
      }
    },
    messages: {
      sname: {
        required: 'Debes colocar tu nombre',
        minlength: 'Tu nombre debe contener por lo menos 5 letras'
      },
      spatente: {
        required: 'Debes ingresar la patente'
      },
      semail: {
        required: 'Debes ingresar tu email',
        email: 'Debes ingresar un email válido'
      },
      smessage: {
        required: 'Debes ingresar un mensaje'
      }
    },
    // errorPlacement: function(error, element) {
    //   error.insertBefore(element);
    // },
    errorPlacement: function(error, element) {
				if (element.is(":radio"))
					error.appendTo(element.parent().parent());
				else if (element.is(":checkbox"))
					error.appendTo(element.parent());
				else
					error.appendTo(element.parent());
		},
    submitHandler: function(form) {
      $.ajax({
        type: 'POST',
        url: $(form).attr('action'),
        data: $(form).serialize(),
      })
      .done(function(response) {
        $(mensajes).removeClass('error');
        $(mensajes).addClass('success');
        $(mensajes).text('Tu mensaje ha sido enviado correctamente');

        $('#csubmit').addClass('success');
        $('#cname').val('');
        $('#cemail').val('');
        $('#cphone').val('');
        $('#cmessage').val('');
      })
      .fail(function(data) {
        $(formMessages).removeClass('success');
        $(formMessages).addClass('error');
        if (data.responseText !== '') {
          $(formMessages).text(data.responseText);
        } else {
          $(formMessages).text('Ha ocurrido un error, no se ha podido enviar el mensaje, intenta nuevamente.');
        }
      });
    }
  });
}
